<template>
    <div class="Users full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-8 pt-5 flex-grow-1 bg-white ">
                <v-row class="d-flex align-center mb-5 filter">
                    <v-col cols="5" class="pl-0 d-flex align-center">
                        <p>{{ $t('general.status') }}:</p>
                        <v-select :items="filterStatus" outlined hide-details="auto" v-model="filterStatusSelected" attach class="ml-6"/>
                    </v-col>
                </v-row>

                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="usersTableHeaders"
                    :items="filteredList"
                >
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="(item) in items" :key="item.uid" @click="editRow(item)">
                                <td>{{ item.username }}</td>
                                <td>{{ item.firstname }} {{ item.lastname }}</td>
                                <td>{{ findGroup(item.groupId) }}</td>
                                <td>{{ item.status }}</td>
                                <td :class="{edit: item.editable && checkUserRights('userManagementEdit'), show: !item.editable || !checkUserRights('userManagementEdit')}"></td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToUserManagement"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="printAll" :successCheck="showPrintSuccess" :progress="showPrintProgress" @footerButtonClick="printAll" :class="{'disabled': !checkUserRights('userManagementPrint')}"></footer-button>
            <footer-button buttonFunction="new" @footerButtonClick="newUser" :class="{'disabled': !checkUserRights('userManagementEdit')}"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Users',
    components: {
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            filterStatusSelected: 'show all',
            showPrintProgress: false,
            showPrintSuccess: false,
        }
    },
    computed: {
        ...mapState([
            'userlist',
            'grouplist',
        ]),
        usersTableHeaders(){
            return [
                { text: this.$t('system.userManagement.username'), align: 'start', value: 'username' },
                { text: this.$t('system.userManagement.displayname'), align: 'start', value: 'firstname' },
                { text: this.$t('system.userManagement.group'), value: 'groupId' },
                { text: this.$t('general.status'), value: 'status' },
                { text:"", sortable: false,}
            ]
        },
        filterStatus(){
            return [
                this.$t('filter.showAll'),
                this.$t('filter.showActive'),
                this.$t('filter.showInactive'),
                this.$t('filter.showLocked')
            ]
        },
        filteredList(){
            let filteredlist = this.$store.state.userlist;
            if (this.filterStatusSelected == this.$t('filter.showActive')){
                filteredlist = filteredlist.filter(function(user){
                    return user.status == 'Active';
                })
            } else if (this.filterStatusSelected == this.$t('filter.showInactive')){
                filteredlist = filteredlist.filter(function(user){
                    return user.status == 'Inactive';
                })
            } else if (this.filterStatusSelected == this.$t('filter.showLocked')){
                filteredlist = filteredlist.filter(function(user){
                    return user.status == 'Locked';
                })
            }
            return filteredlist;
        }
    },
    methods:{
        goToUserManagement(){
            this.$router.push('user-management');
        },
        newUser(){
            let user = {
                id: null,
                uid: "",
                username: "",
                firstname: "",
                lastname: "",
                groupId: null,
                password: "",
                validity: 0,
                forcePasswordChange: true,
                editable: true,
                status: "Active"
            }
            this.$store.commit('ChangeEditUser', user);
        },
        printAll(){
            this.showPrintProgress = true;
            this.$store.dispatch('getAxiosNoSetter', '/admin/user/printAll')
            .then(response =>{
                this.showPrintProgress = false;
                if (response.status === 200){
                    this.showPrintSuccess = true;
                    setTimeout(()=>{
                        this.showPrintSuccess = false;
                    }, 800)
                }
            });
        },
        editRow(user){
            this.$store.commit('ChangeEditUser', user);
        },
        findGroup(groupId){
            let groupName = this.grouplist.find(function(group){
                return group.id == groupId;
            });
            if (groupName){
                groupName = groupName.name;
            } else {
                groupName = '–'
            }
            return groupName;
        }
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/group/list', 'grouplist']);
        this.$store.dispatch('getAxiosSetter', ['/admin/user/list', 'userlist']);
    },
}
</script>
<style scoped lang="scss">
</style>